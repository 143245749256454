import { Box, Typography } from "@mui/material";
import React from "react";

const ProductAdmin = () => {
  return (
    <Box p={3}>
      <Typography>Work in progress... </Typography>
      <Typography>
        This page will allow an admin member to easily filter products and bulk
        modify which viewable collection they should be in.
      </Typography>
    </Box>
  );
};

export default ProductAdmin;
