import { useQuery } from "@tanstack/react-query";
import { toJSON } from "../queryUtils";
import Cookies from "js-cookie";
import { jwtStringToObject } from "../../utils";

const getMemberIdFromCookie = () => {
  const jwtString = Cookies.get("_jwt");
  if (!jwtString) return null;
  const memberObj = jwtStringToObject(jwtString);
  return memberObj.id;
};

export const useCurrentMember = () => {
  const memberId = getMemberIdFromCookie();
  return useQuery(["current-member"], () => getMember(memberId), {
    enabled: !!memberId,
    staleTime: 100000,
  });
};
export const useMember = (memberId) => {
  return useQuery(["member", memberId], () => getMember(memberId), {
    enabled: !!memberId,
    staleTime: 100000,
  });
};

export const useMembers = () => {
  return useQuery(["members"], getMembers, {
    staleTime: 100000,
  });
};

const getMember = async (memberId) => {
  return await fetch(
    `${process.env.REACT_APP_WIX_FUNCTIONS_BASE_URL}/member/${memberId}`
  ).then(toJSON);
};

const getMembers = async () => {
  return await fetch(
    `${process.env.REACT_APP_WIX_FUNCTIONS_BASE_URL}/members`
  ).then(toJSON);
};
