import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  TextField,
  Toast,
  Snackbar,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-location";
import { useState, useRef } from "react";
import { exportComponentAsPNG } from "react-component-export-image";
import QRCode from "react-qr-code";
import { useMemberContext } from "..";
import { ContentCopy } from "@mui/icons-material";

export const LogoutDialog = ({ open, onResolve, onReject }) => {
  const { logout } = useMemberContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleConfirmClick = () => {
    logout();
    queryClient.resetQueries();
    navigate({ to: "/login" });
    onResolve();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Are you sure you would like to logout?</DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions>
        <Button onClick={onReject}>Cancel</Button>
        <Button onClick={handleConfirmClick} color="secondary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const DownloadQrDialog = ({ open, onResolve, onReject, qrCode }) => {
  const qrRef = useRef();
  const [customLabel, setCustomLabel] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const downloadImage = (fileName) => {
    exportComponentAsPNG(qrRef, { fileName });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const fieldValues = Object.fromEntries(formData.entries());
    downloadImage(fieldValues.fileName);
    onResolve();
  };

  return (
    <Dialog open={open}>
      {openSnackbar && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          sx={{ zIndex: 1000000 }}
          message={"Your QR code has been copied to the clipboard."}
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={() => setOpenSnackbar(false)}
        />
      )}
      <DialogTitle>Download QR image</DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent sx={{ p: 2 }}>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              name="fileName"
              type="text"
              required
              label="Filename"
              fullWidth
            />
            <TextField
              name="customlabel"
              type="text"
              value={customLabel}
              onChange={(e) => setCustomLabel(e.target.value)}
              label="Custom Label"
              fullWidth
            />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap={2}
            >
              <Box
                border={({ palette }) => `2px solid ${palette.grey[500]}`}
                borderRadius={1}
              >
                <Box
                  ref={qrRef}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  p={2}
                >
                  <Typography
                    align="center"
                    textOverflow="break-word"
                    overflow="hidden"
                  >
                    {customLabel}
                  </Typography>
                  <QRCode value={qrCode} />
                  <Typography fontWeight={700} fontSize={24} align="center">
                    3v3i
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ p: 2, display: "flex", gap: 2, flexDirection: "column" }}
        >
          <Button
            sx={{ flexBasis: "100%", textTransform: "none" }}
            startIcon={<ContentCopy />}
            onClick={() => {
              if ("clipboard" in navigator) {
                navigator.clipboard.writeText(qrCode);
                setOpenSnackbar(true);
              }
            }}
          >
            Copy URL to clipboard
          </Button>
          <Box display="flex" gap={2}>
            <Button type="submit">Download QR Image</Button>
            <Button onClick={onReject}>Cancel</Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
};
