import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Controller } from "react-hook-form";

export default function FormTextField({ name, control, label, type }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <FormControl size="small" variant="outlined" error={!!error}>
            <InputLabel htmlFor="email-input">{label}</InputLabel>
            <OutlinedInput
              size="small"
              type={type}
              id={`${label}-text-input`}
              label={label}
              {...field}
            />
            <FormHelperText>{error?.message ?? ""}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
}
