import { Alert, Box, Button, Checkbox, Typography } from "@mui/material";
import { useMatch } from "@tanstack/react-location";
import { useRef, useState, useEffect } from "react";
import LoadingBackdrop from "../components/LoadingBackdrop";
import {
  useEditMemberProductCollections,
  useInsertMemberProductCollections,
} from "../services/wixCollections/mutations";
import {
  useCollections,
  useMemberProductCollections,
} from "../services/wixCollections/queries";
import { useMember } from "../services/wixMembers/queries";

export default function EditMemberProductCollections() {
  const {
    params: { memberId },
  } = useMatch();
  const { data: member } = useMember(memberId);
  // All collections
  const { data: collections, isLoading: isLoadingCollections } =
    useCollections();
  // Selected member's permitted product collections
  const {
    data: memberProductCollections,
    isLoading: isLoadingProductCollections,
    isFetching: isFetchingProductCollections,
  } = useMemberProductCollections(memberId);
  // Member's product collections mutation
  const {
    mutate: editMemberProductCollections,
    isLoading: isLoadingEditMemberProductCollections,
  } = useEditMemberProductCollections();
  const {
    mutate: insertMemberProductCollections,
    isLoading: isLoadingInsertMemberProductCollections,
  } = useInsertMemberProductCollections();

  const createInitialCollectionCheckedMap = () => {
    return collections?.reduce(
      (acc, curr) => ({
        ...acc,
        [curr._id]: !!memberProductCollections?.find(
          ({ _id }) => _id === curr._id
        ),
      }),
      {}
    );
  };

  const [collectionCheckedMap, setCollectionCheckedMap] = useState();
  const [isDirty, setIsDirty] = useState(false);
  const collectionCheckedMapCopy = useRef();

  useEffect(() => {
    if (!isLoadingCollections && !isLoadingProductCollections) {
      const initialCollectionCheckedMap = createInitialCollectionCheckedMap();
      setCollectionCheckedMap(initialCollectionCheckedMap);
      collectionCheckedMapCopy.current = initialCollectionCheckedMap;
    }
  }, [isLoadingCollections, isLoadingProductCollections]);

  const handleSaveChangesClick = () => {
    const productCollections = Object.entries(collectionCheckedMap)
      .filter(([_, value]) => Boolean(value))
      .map(([key, _]) => key);

    editMemberProductCollections(
      {
        collectionId: memberProductCollections._id,
        productCollections,
      },
      { onSuccess: () => setIsDirty(false) }
    );
  };

  if (!memberProductCollections && !isLoadingProductCollections)
    return (
      <Box flex={1}>
        <Box
          height="100%"
          display="flex"
          justifyContent={"center"}
          alignItems="center"
        >
          <Box
            maxWidth={500}
            border={({ palette }) => `1px solid ${palette.grey[500]}`}
            borderRadius={({ shape }) => shape.borderRadius}
            p={3}
            display="flex"
            flexDirection={"column"}
            gap={2}
          >
            <Typography align="center">{member?.name}</Typography>
            <Alert severity="info">
              This member has not been added yet. They will be able to view the
              publically available products. Add this member to collection to
              edit their product permissions.
            </Alert>
            <Button
              onClick={() =>
                insertMemberProductCollections({
                  memberId: member._id,
                })
              }
              sx={{ alignSelf: "center" }}
            >
              Create for user
            </Button>
          </Box>
        </Box>
      </Box>
    );

  return (
    <Box flex={1}>
      <LoadingBackdrop
        isLoading={
          isLoadingProductCollections ||
          isFetchingProductCollections ||
          isLoadingEditMemberProductCollections
        }
      />

      <Box
        height={"100%"}
        display="flex"
        justifyContent={"center"}
        alignItems="center"
      >
        <Box
          width={"500px"}
          border={({ palette }) => `1px solid ${palette.grey[500]}`}
          p={3}
          borderRadius={({ shape }) => shape.borderRadius}
        >
          <Typography align="center">{member?.name}</Typography>
          {!collectionCheckedMap ? (
            <></>
          ) : (
            <Box>
              {collections?.map(({ name, _id }) => {
                return (
                  <Box
                    key={_id}
                    display={"flex"}
                    alignItems="center"
                    justifyContent={"space-between"}
                  >
                    <Typography>{name}</Typography>
                    <Checkbox
                      color="secondary"
                      checked={!!collectionCheckedMap[_id]}
                      onChange={(e) => {
                        setIsDirty(true);
                        setCollectionCheckedMap((prev) => ({
                          ...prev,
                          [_id]: e.target.checked,
                        }));
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
          )}
          <Box display="flex" gap={2}>
            <Button
              disabled={!isDirty}
              onClick={() => {
                setIsDirty(false);
                setCollectionCheckedMap(collectionCheckedMapCopy.current);
              }}
            >
              Discard Changes
            </Button>
            <Button
              disabled={isLoadingProductCollections || !isDirty}
              onClick={() => {
                handleSaveChangesClick();
              }}
            >
              Save Changes
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
