import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useEditMemberProductCollections = () => {
  const queryClient = useQueryClient();
  return useMutation(editMemberProductCollections, {
    onSuccess: (_, variables) => {
      queryClient.refetchQueries(["memberProductCollections"]);
    },
  });
};
export const useInsertMemberProductCollections = () => {
  const queryClient = useQueryClient();
  return useMutation(insertMemberProductCollections, {
    onSuccess: (_, variables) => {
      queryClient.refetchQueries(["memberProductCollections"]);
    },
  });
};

const editMemberProductCollections = (memberProductData) => {
  return fetch(
    `${process.env.REACT_APP_WIX_FUNCTIONS_BASE_URL}/editMemberProductCollections`,
    {
      method: "PUT",
      body: JSON.stringify(memberProductData),
    }
  );
};
const insertMemberProductCollections = (body) => {
  return fetch(
    `${process.env.REACT_APP_WIX_FUNCTIONS_BASE_URL}/insertMemberProductCollections`,
    {
      method: "PUT",
      body: JSON.stringify(body),
    }
  );
};
