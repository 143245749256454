import { Box, Button, Container, lighten, TextField } from "@mui/material";
import { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useCurrentMember } from "../services/wixMembers/queries";
import {
  useCollections,
  useMemberProductCollections,
} from "../services/wixCollections/queries";
import { useProducts } from "../services/wixProducts/queries";
import LoadingBackdrop from "../components/LoadingBackdrop";
import FilterBar from "../components/FilterBar";
import ProductDrawer from "../components/ProductDrawer";
import { useProductIdMap } from "../hooks";

export default function QrBuilder() {
  const { productIdMap, addProduct, deleteProduct, updateProductQuantity } =
    useProductIdMap();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [filterParamObject, setFilterParamObject] = useState();

  const [
    permittedCollectionsFilterOptions,
    setPermittedCollectionsFilterOptions,
  ] = useState([]);

  const { data: currentMember, isLoading: isLoadingCurrentMember } =
    useCurrentMember();

  const {
    data: memberProductCollections,
    isLoading: isLoadingProductCollections,
  } = useMemberProductCollections(currentMember?._id);

  const { data: collections } = useCollections({ enabled: !!currentMember });

  const productCollectionIds = memberProductCollections?.map(({ _id }) => _id);

  const permittedCollections = collections?.filter(({ _id }) => {
    return productCollectionIds?.includes(_id);
  });

  useEffect(() => {
    if (!isLoadingCurrentMember && !!currentMember) {
      const memberProdCollectionsCopy = memberProductCollections?.map((i) => i);
      setPermittedCollectionsFilterOptions(memberProdCollectionsCopy);
    }
  }, [memberProductCollections]);

  const createSearchParams = () => {
    const limit = pageSize;
    const skip = page * pageSize;

    return { ...filterParamObject, limit, skip };
  };

  const [rowCountState, setRowCountState] = useState(0);

  useEffect(() => {
    createSearchParams();
  }, [page]);

  const {
    data: products,
    isLoading: isLoadingProducts,
    isFetching: isFetchingProducts,
  } = useProducts(createSearchParams(), {
    onSuccess: (data) => setRowCountState(data.totalCount),
  });

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    {
      field: "inStock",
      headerName: "In Stock",
      flex: 0.5,
      renderCell: ({ row }) => (row.inStock ? "yes" : "no"),
      cellClassName: ({ row }) => (row.inStock ? "" : "out-of-stock-bg"),
    },
    // {
    //   field: "price",
    //   filterable: false,
    //   headerName: "Price",
    //   flex: 0.5,
    //   renderCell: ({ row }) => row.formattedPrice,
    // },
    {
      field: "add_product",
      headerName: "",
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => (
        <AddProductCell addProduct={addProduct} product={row} />
      ),
    },
  ];

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      {/* <LoadingBackdrop isLoading={isLoadingProducts} /> */}
      <Box display="flex" p={3} flex={1} gap={2}>
        <FilterBar
          permittedCollections={permittedCollections}
          setFilters={setFilterParamObject}
        />
        <Box
          width="100%"
          height="100%"
          flex={3}
          // border={({ palette }) => `1px solid ${palette.grey[300]}`}
        >
          <Box
            height="100%"
            display="flex"
            gap={2}
            flexDirection="column"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Box
              display="flex"
              alignItems="center"
              gap={2}
              width="100%"
              height="100%"
            >
              <DataGrid
                sortingMode="server"
                paginationMode="server"
                page={page}
                pageSize={pageSize}
                onPageChange={(newPage) => {
                  setPage(newPage);
                }}
                rowCount={rowCountState}
                density="standard"
                disableColumnMenu
                loading={isFetchingProducts}
                getRowId={(row) => row._id}
                rows={products?.items ?? []}
                columns={columns}
                disableSelectionOnClick
                sx={{
                  "& .out-of-stock-bg": {
                    background: ({ palette }) =>
                      lighten(palette.warning.light, 0.8),
                    "&:hover": {
                      background: ({ palette }) =>
                        lighten(palette.warning.light, 0.8),
                    },
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
        <ProductDrawer
          productIdMap={productIdMap}
          deleteProduct={deleteProduct}
          updateProductQuantity={updateProductQuantity}
        />
      </Box>
    </Container>
  );
}

const AddProductCell = ({ addProduct, product }) => {
  const [quantity, setQuantity] = useState(0);
  return (
    <Box display="flex" gap={2}>
      <TextField
        sx={{ maxWidth: "100px" }}
        value={quantity}
        onChange={(e) => setQuantity(e.target.value < 0 ? 0 : e.target.value)}
        type="number"
      />
      <Button
        disabled={quantity <= 0}
        color="secondary"
        onClick={() => {
          addProduct({
            id: product._id,
            quantity: quantity,
            name: product.name,
          });
          setQuantity(0);
        }}
      >
        Add
      </Button>
    </Box>
  );
};
