import { Box, Button, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate, useRouter } from "@tanstack/react-location";

import { useMembers } from "../services/wixMembers/queries";

export default function MemberProductCollections() {
  const { data: members, isLoading } = useMembers();
  const navigate = useNavigate();
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "select",
      headerName: "",
      flex: 2,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Button
            variant="outlined"
            onClick={() => {
              navigate({ to: `edit/${row._id}` });
            }}
          >
            Edit
          </Button>
        );
      },
    },
  ];

  return (
    <Box display={"flex"} flexDirection="column" height={"100%"}>
      <Box px={5} pt={2}>
        <Typography>
          Manage which product collections each member is allowed to view in the
          QR Builder.
        </Typography>
      </Box>
      <Box height="100%" width="100%" flex={1}>
        <Box height="100%" p={5}>
          <DataGrid
            loading={isLoading}
            rows={members?.items ?? []}
            columns={columns}
            disableColumnMenu
            disableSelectionOnClick
            getRowId={(row) => row._id}
            density="compact"
          />
        </Box>
      </Box>
    </Box>
  );
}
