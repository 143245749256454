import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

export const jwtStringToObject = (jwtString) => {
  const jwt = jwtString.replace("JWS.", "");

  const decoded = jwt_decode(jwt);
  const parsed = JSON.parse(decoded.data);
  return parsed;
};

export const getJwtMemberFromCookie = () => {
  const jwtString = Cookies.get("_jwt");
  if (!jwtString) return null;
  return jwtStringToObject(jwtString);
};

export const createJwtMemberObject = (_jwt) => {
  return jwtStringToObject(_jwt);
};
