import { useQuery } from "@tanstack/react-query";
import { toJSON } from "../queryUtils";

export const useProducts = (filters, options) => {
  return useQuery(["products", filters], () => getProducts(filters), {
    retry: 3,
    retryDelay: 2000,
    staleTime: 0,
    cacheTime: 0,
    enabled: !!filters,
    ...options,
  });
};

const getProducts = async (searchParams) => {
  const productIdUrlString = new URLSearchParams(searchParams).toString();
  return await fetch(
    `${process.env.REACT_APP_WIX_FUNCTIONS_BASE_URL}/products?${productIdUrlString}`
  ).then(toJSON);
};
