import { Button, Box, Typography, Link as MuiLink } from "@mui/material";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import FormTextField from "../components/FormTextField";
import Cookies from "js-cookie";
import { useQueryClient } from "@tanstack/react-query";
import { useLogMemberIn } from "../services/wixLogin/mutations";
import { Link, useNavigate } from "@tanstack/react-location";
import LoadingBackdrop from "../components/LoadingBackdrop";
import { useMemberContext } from "..";

export default function Login() {
  const { login } = useMemberContext();
  const queryClient = useQueryClient();
  const { mutate: logMemberIn, isLoading } = useLogMemberIn();
  const navigate = useNavigate();

  const schema = z.object({
    email: z.string().email(),
    password: z.string().min(1, "Please enter your password"),
  });

  const { handleSubmit, control } = useForm({
    defaultValues: { email: "", password: "" },
    resolver: zodResolver(schema),
  });

  const onSubmit = (formData, e) => {
    e.preventDefault();

    logMemberIn(
      { email: formData.email, password: formData.password },
      {
        onSuccess: (data) => {
          login(data.jwt);
          queryClient.invalidateQueries(["current-member"]);
          queryClient.refetchQueries(["current-member"]);
          navigate({ to: "/" });
        },
        onError: () => {},
      }
    );
  };

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems="center"
      height={"100%"}
    >
      <LoadingBackdrop isLoading={isLoading} />
      <Box
        display={"flex"}
        flexDirection="column"
        gap={2}
        width="35%"
        border={({ palette }) => `1px solid ${palette.grey[500]}`}
        borderRadius={({ shape }) => shape.borderRadius}
        p={2}
      >
        <Typography align="center">
          Login to your 3V3i account to use the 3V3i QR Builder
        </Typography>
        <Box
          onSubmit={handleSubmit(onSubmit)}
          component={"form"}
          noValidate
          display={"flex"}
          flexDirection={"column"}
          gap={2}
        >
          <FormTextField
            name="email"
            type="email"
            label="Email"
            control={control}
          />
          <FormTextField
            type="password"
            name="password"
            label="Password"
            control={control}
          />
          <MuiLink
            component={Link}
            sx={{
              fontSize: "14px",
              color: ({ palette }) => palette.secondary.main,
              textAlign: "center",
            }}
            href="https://www.3v3i.com/"
          >
            Don't have a 3V3i account? Click here to create one
          </MuiLink>
          <Button
            type="submit"
            color="secondary"
            sx={{ alignSelf: "flex-end" }}
          >
            Log in
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
