import { useQuery } from "@tanstack/react-query";
import { toJSON } from "../queryUtils";

export const useCollections = (options) => {
  return useQuery(["collections"], () => getCollections(), {
    staleTime: 100000,
    select: (data) => data.items,
    ...options,
  });
};

export const useMemberProductCollections = (memberId) => {
  return useQuery(
    ["memberProductCollections", memberId],
    () => getMemberProductCollections(memberId),
    {
      staleTime: 100000,
      enabled: !!memberId,
      select: (data) => {
        return data.items?.length > 0 ? data.items[0].productCollections : null;
      },
    }
  );
};

const getCollections = async () => {
  return await fetch(
    `${process.env.REACT_APP_WIX_FUNCTIONS_BASE_URL}/collections`
  ).then(toJSON);
};

const getMemberProductCollections = async (memberId) => {
  return await fetch(
    `${process.env.REACT_APP_WIX_FUNCTIONS_BASE_URL}/memberProductCollections/${memberId}`
  ).then(toJSON);
};
