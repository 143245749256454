import React, { useContext, createContext, useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/system";
import { CssBaseline, createTheme } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Cookies from "js-cookie";
import { createJwtMemberObject, getJwtMemberFromCookie } from "./utils";

const theme = createTheme({
  shape: { borderRadius: 0 },
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
  palette: {
    primary: {
      main: "#B99956",
    },
    secondary: {
      main: "#111111",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
          fontFamily: "Montserrat, sans-serif",
        },
        html: {
          width: "100%",
          height: "100%",
          WebkitOverflowScrolling: "touch",
        },
        body: {
          width: "100%",
          height: "100%",
        },
        a: {
          textDecoration: "none",
        },
        "#root": {
          width: "100%",
          height: "100%",
        },
      },
    },
    MuiTextField: { defaultProps: { size: "small" } },
    MuiButton: {
      defaultProps: {
        variant: "contained",
        size: "small",
        sx: { textTransform: "none" },
      },
    },
    MuiChip: { defaultProps: { size: "small" } },
    MuiLink: { defaultProps: { underline: "none" } },
  },
});

const MemberContext = createContext();
export const useMemberContext = () => useContext(MemberContext);

const MemberProvider = ({ children }) => {
  const [jwtMember, setJwtMember] = useState(null);

  const logout = () => {
    Cookies.remove("_jwt");
    setJwtMember(null);
  };

  const login = (_jwt) => {
    Cookies.set("_jwt", _jwt, { expires: 7 });
    const jwtMemberObj = createJwtMemberObject(_jwt);
    setJwtMember(jwtMemberObj);
  };

  const setMemberOnLoad = () => {
    const jwtMemberObj = getJwtMemberFromCookie();
    setJwtMember(jwtMemberObj);
  };

  useEffect(() => {
    setMemberOnLoad();
  }, []);
  return (
    <MemberContext.Provider value={{ jwtMember, login, logout }}>
      {children}
    </MemberContext.Provider>
  );
};

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: 3 } },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MemberProvider>
          <App />
        </MemberProvider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
