import {
  Box,
  Typography,
  Autocomplete,
  CircularProgress,
  TextField,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import {
  useCollections,
  useMemberProductCollections,
} from "../services/wixCollections/queries";
import { useCurrentMember } from "../services/wixMembers/queries";
import { useDebounce, useProductFilters } from "../hooks";

export default function FilterBar({ setFilters }) {
  const { data: currentMember, isLoading: isLoadingCurrentMember } =
    useCurrentMember();

  const {
    data: memberProductCollections,
    isLoading: isLoadingMemberProductCollections,
  } = useMemberProductCollections(currentMember?._id);

  const { data: collections } = useCollections();

  const productCollectionIds = memberProductCollections?.map(({ _id }) => _id);

  const permittedCollections = collections?.filter(({ _id }) => {
    return productCollectionIds?.includes(_id);
  });

  const [nameFilter, setNameFilter] = useState("");
  const debouncedName = useDebounce(nameFilter, 300);
  const [productFilters, dispatchProductFilters] = useProductFilters();

  const createParamObj = () => {
    let filtersCopy = { ...productFilters };

    if (filtersCopy.collections.length === 0) {
      delete filtersCopy.collections;
    } else {
      filtersCopy.collections = filtersCopy.collections.map(({ _id }) => _id);
    }

    return filtersCopy;
  };

  useEffect(() => {
    setFilters(createParamObj());
  }, [productFilters]);

  useEffect(() => {
    dispatchProductFilters({ type: "name", payload: debouncedName });
  }, [debouncedName]);

  return (
    <Box
      border={({ palette }) => `1px solid ${palette.grey[300]}`}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      p={2}
      flex={1}
    >
      <Typography align="center" fontSize={20}>
        Product Filters
      </Typography>
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <TextField
          label="Name"
          value={nameFilter}
          onChange={(e) => setNameFilter(e.target.value.trim())}
        />
        <Autocomplete
          // sx={{ width: "300px" }}
          multiple
          filterSelectedOptions
          options={permittedCollections ?? []}
          value={productFilters.collections}
          onChange={(event, newValue) =>
            dispatchProductFilters({ type: "collections", payload: newValue })
          }
          getOptionLabel={(option) => option?.name ?? ""}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Product Collections"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {false ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
        />
      </Box>
    </Box>
  );
}
