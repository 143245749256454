import { useState, useEffect, useReducer } from "react";
// useDebounce
export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}

// useProductFilters
const initialState = {
  name: "",
  collections: [],
};

function reducer(state, { type, payload }) {
  switch (type) {
    case "reset":
      return initialState;
    case "name":
      return { ...state, name: payload };
    case "collections":
      return { ...state, collections: payload };
  }
}

export function useProductFilters() {
  return useReducer(reducer, initialState);
}

export const useProductIdMap = () => {
  const [productIdMap, setProductIdMap] = useState({});

  const addProduct = ({ id, quantity, name }) => {
    setProductIdMap((prev) => ({ ...prev, [id]: { quantity, name } }));
  };

  const deleteProduct = (product) => {
    setProductIdMap((prev) => {
      delete prev[product];
      return { ...prev };
    });
  };

  const updateProductQuantity = (productId, quantity) => {
    setProductIdMap((prev) => ({
      ...prev,
      [productId]: { ...prev[productId], quantity },
    }));
  };

  return { productIdMap, addProduct, deleteProduct, updateProductQuantity };
};
