import { DeleteOutline } from "@mui/icons-material";
import {
  Box,
  Typography,
  Button,
  TextField,
  Divider,
  Chip,
  IconButton,
} from "@mui/material";
import { useState, useRef } from "react";
import { DownloadQrDialog } from "./dialogs";
import { fireDialog } from "./PromiseModal/fireDialog";
//TODO

export default function ProductDrawer({
  productIdMap,
  updateProductQuantity,
  deleteProduct,
}) {
  const createDownloadQrCode = () => {
    const productIdArray = Object.entries(productIdMap);
    const tempArray = productIdArray.map(([productId, { quantity }]) => {
      return [`qrId_${productId}`, quantity];
    });
    const qrIdQeuryString = new URLSearchParams(tempArray).toString();
    //TODO add env in netlify
    return `${process.env.REACT_APP_WIX_CART_URL}&${qrIdQeuryString}`;
  };

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      p={3}
      border={({ palette }) => `1px solid ${palette.grey[300]}`}
      borderRadius={1}
      flex={1}
      gap={1}
    >
      <Typography align="center" fontSize="20px">
        Edit QR Code Products
      </Typography>

      <Box
        display="flex"
        flexDirection="column"
        gap={0}
        flex={1}
        flexBasis="0"
        overflow="auto"
      >
        {Object.entries(productIdMap).map(([productId, { name, quantity }]) => (
          <ProductListItem
            productId={productId}
            name={name}
            quantity={quantity}
            deleteProduct={deleteProduct}
            updateProductQuantity={updateProductQuantity}
          />
        ))}
      </Box>
      <Button
        size="large"
        onClick={() => {
          fireDialog((promiseProps) =>
            DownloadQrDialog({
              ...promiseProps,
              qrCode: createDownloadQrCode(),
            })
          );
        }}
        disabled={Object.entries(productIdMap).length <= 0}
        sx={{
          alignSelf: "center",
          textTransform: "none",
        }}
      >
        Create QR Code
      </Button>
    </Box>
  );
}

const ProductListItem = ({
  productId,
  quantity,
  name,
  deleteProduct,
  updateProductQuantity,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const editQuantityRef = useRef(null);

  const handleToggleIsEditing = () => {
    if (isEditing) {
      updateProductQuantity(productId, editQuantityRef.current.value);
    }

    setIsEditing((prev) => !prev);
  };
  return (
    <Box
      borderRadius={4}
      p={1}
      display="flex"
      alignItems="center"
      key={productId}
      flexWrap="wrap"
      gap={1}
    >
      <Typography
        fontSize={14}
        sx={{ flexBasis: "100%" }}
      >{`${name}`}</Typography>
      <Box display={"flex"} gap={1} alignItems="center">
        <TextField
          inputRef={editQuantityRef}
          type="number"
          sx={{ flexBasis: "80px" }}
          value={quantity}
          onChange={(e) => {
            if (e.target.value >= 0)
              updateProductQuantity(productId, e.target.value);
          }}
        />
        {/* <Button onClick={handleToggleIsEditing}>
          {`${isEditing ? "Save" : "Edit"}`} Quantity
        </Button> */}
        <IconButton
          aria-label="delete"
          onClick={() => deleteProduct(productId)}
        >
          <DeleteOutline />
        </IconButton>
      </Box>
      <Divider sx={{ flexBasis: "100%" }} />
    </Box>
  );
};
