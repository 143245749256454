import {
  AppBar,
  Box,
  Button,
  Container,
  Link as MuiLink,
  Toolbar,
} from "@mui/material";
import {
  ReactLocation,
  Router,
  Outlet,
  Link,
  Navigate,
} from "@tanstack/react-location";
import EveLogo from "./assets/3V3I-logo.png";
import { LogoutDialog } from "./components/dialogs";
import { fireDialog } from "./components/PromiseModal/fireDialog";
import Admin from "./pages/Admin";
import EditMemberProductCollections from "./pages/EditMemberProductCollections";
import QrBuilder from "./pages/QrBuilder";
import Login from "./pages/Login";
import MemberProductCollections from "./pages/MemberProductCollections";

import ModalContainer from "./components/PromiseModal";
import ProductAdmin from "./pages/ProductAdmin";
import { useMemberContext } from ".";

const reactLocation = new ReactLocation();

export default function App() {
  const { jwtMember } = useMemberContext();

  const handleLogoutClick = () => fireDialog(LogoutDialog);

  let routes = [
    {
      path: "/",
      element: (
        <MemberRoute>
          <QrBuilder />
        </MemberRoute>
      ),
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "admin",
      children: [
        {
          path: "/",
          element: (
            <AdminRoute>
              <Admin />
            </AdminRoute>
          ),
        },
        {
          path: "members",
          children: [
            {
              path: "/",
              element: (
                <AdminRoute>
                  <MemberProductCollections />
                </AdminRoute>
              ),
            },
            {
              path: "edit",
              children: [
                {
                  path: ":memberId",
                  element: (
                    <AdminRoute>
                      <EditMemberProductCollections />
                    </AdminRoute>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: "products",
          children: [
            {
              path: "/",
              element: (
                <AdminRoute>
                  <ProductAdmin />
                </AdminRoute>
              ),
            },
          ],
        },
      ],
    },
  ];

  //TODO logo size
  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        minWidth: "768px",
      }}
    >
      <Router location={reactLocation} routes={routes}>
        <AppBar position="relative" color="inherit">
          <Toolbar sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"space-between"}
              gap={3}
            >
              <Box height="70px">
                <img className="eve-logo" src={EveLogo} />
              </Box>
              <Box
                display={"flex"}
                flex={2}
                gap={2}
                alignItems="center"
                justifyContent={"flex-end"}
              >
                <MuiLink
                  component={Link}
                  color="secondary"
                  to="/"
                  textTransform="uppercase"
                  sx={{
                    transition: "color 100ms",
                    "&:hover": {
                      color: ({ palette }) => palette.primary.main,
                    },
                  }}
                >
                  QR Builder
                </MuiLink>
                <MuiLink
                  component={"a"}
                  color="secondary"
                  href="https://www.3v3i.com"
                  textTransform="uppercase"
                  sx={{
                    transition: "color 100ms",
                    "&:hover": {
                      color: ({ palette }) => palette.primary.main,
                    },
                  }}
                >
                  3V3i
                </MuiLink>
                {jwtMember?.admin && (
                  <MuiLink
                    component={Link}
                    to="/admin"
                    textTransform="uppercase"
                    color="secondary"
                    sx={{
                      transition: "color 100ms",
                      "&:hover": {
                        color: ({ palette }) => palette.primary.main,
                      },
                    }}
                  >
                    Admin
                  </MuiLink>
                )}
                <Box display="flex" alignItems={"center"} gap={3}>
                  {!jwtMember ? (
                    <MuiLink component={Link} to="/login">
                      <Button color="secondary">Login</Button>
                    </MuiLink>
                  ) : (
                    <Button onClick={handleLogoutClick} color="secondary">
                      Logout
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Outlet />
        <ModalContainer />
      </Router>
    </Container>
  );
}

const MemberRoute = ({ children }) => {
  const { jwtMember } = useMemberContext();
  if (!jwtMember) return <Navigate to="/login" />;
  return <>{children}</>;
};
const AdminRoute = ({ children }) => {
  const { jwtMember } = useMemberContext();
  if (!jwtMember?.admin) return <></>;
  return <>{children}</>;
};
