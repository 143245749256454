import { useMutation } from "@tanstack/react-query";
import { toJSON } from "../queryUtils";

export const useLogMemberIn = () => {
  return useMutation((loginObj) => loginMemberIn(loginObj));
};

const loginMemberIn = ({ email, password }) => {
  return fetch("https://www.eveinitiatives.com/_functions/login", {
    method: "POST",
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  }).then(toJSON);
};
